import React, { useEffect, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 28.663270950317383,
  lng: -106.12863159179688,
};

function SimpleMap() {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const { AdvancedMarkerElement } = window.google.maps.marker;
      const map = mapRef.current.state.map;

      // Crear un marcador avanzado
      new AdvancedMarkerElement({
        map,
        position: center,
        title: 'My Location',
      });
    }
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBFyiH_beqP4liMBm_QyFy_1T0p8IvGrxk">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={(map) => (mapRef.current = map)}
      />
    </LoadScript>
  );
}

export default SimpleMap;
