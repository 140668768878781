import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Archivo de estilos
import logo from './assets/VerLogo.png'; // Logo de la aplicación
import homeImage from './assets/home.jpg'; // Imagen de fondo para la sección Home
import SimpleMap from './components/SimpleMap'; // Componente del mapa
import emailjs from '@emailjs/browser';

function App() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showMessage, setShowMessage] = useState(false); // Estado para el mensaje de confirmación

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Evita el comportamiento predeterminado del formulario

    emailjs
      .sendForm(
        'service_4zy9zzr', // Reemplaza con tu Service ID
        'template_ngkg2x4', // Reemplaza con tu Template ID
        form.current, // Referencia al formulario
        'mQJkuxgEwKrplEIXx' // Reemplaza con tu Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowMessage(true); // Muestra el mensaje
          setTimeout(() => setShowMessage(false), 3000); // Oculta el mensaje después de 3 segundos
        },
        (error) => {
          console.log(error.text);
          alert('Ocurrió un error al enviar el correo');
        }
      );

    e.target.reset(); // Limpia el formulario después de enviarlo
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setIsNavbarVisible(false); // Ocultar navbar al hacer scroll hacia abajo
      } else {
        setIsNavbarVisible(true); // Mostrar navbar al hacer scroll hacia arriba
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <div className="app-container">
      {/* Navbar */}
      <header className={`navbar ${isNavbarVisible ? 'visible' : 'hidden'}`}>
        <nav className="navbar-container">
          <div className="logo">
            <img src={logo} alt="Black Lion Logo" />
          </div>
          <ul className="navbar-links">
            <li><a href="#home">Inicio</a></li>
            <li><a href="#services">Servicios</a></li>
            <li><a href="#about">Nosotros</a></li>
            <li><a href="#contact">Contacto</a></li>
          </ul>
        </nav>
      </header>

      {/* Sección Home */}
      <section
        id="home"
        className="home"
        style={{
          backgroundImage: `url(${homeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h1>Black Lion</h1>
        <p>Expertos en Servicios Contables y Fiscales para optimizar tus recursos y garantizar el cumplimiento normativo.</p>
        <a href="#contact" className="btn">Contáctanos Ahora</a>
      </section>

        {/* Sección Servicios */}
<section id="services" className="services section">
  <h2>Nuestros Servicios</h2>
  <div className="services-container">
    <div className="service-card">
      <h3>Declaraciones Fiscales</h3>
      <p>Cumplimiento tributario con precisión y sin preocupaciones.</p>
    </div>
    <div className="service-card">
      <h3>Contabilidad Empresarial</h3>
      <p>Gestión contable para empresas y emprendedores.</p>
    </div>
    <div className="service-card">
      <h3>Gestión de Nóminas</h3>
      <p>Procesos eficientes para administrar tu nómina.</p>
    </div>
    <div className="service-card">
      <h3>Auditorías Contables</h3>
      <p>Identifica y corrige problemas financieros oportunamente.</p>
    </div>
    <div className="service-card">
      <h3>Planeación Fiscal</h3>
      <p>Asesoría personalizada para optimizar tus recursos.</p>
    </div>
    <div className="service-card">
      <h3>Consultoría Financiera</h3>
      <p>Asesoría estratégica para maximizar el rendimiento de tus finanzas.</p>
    </div>
  </div>
</section>

{/* Sección Nosotros */}
<section id="about" className="about section">
  <h2>Sobre Nosotros</h2>
  <div className="about-container">
    <div className="about-text">
      <p>
        En <strong>Black Lion</strong>, nuestra misión es proporcionar servicios contables y fiscales
        de alta calidad que permitan a nuestros clientes enfocarse en el crecimiento de sus negocios,
        mientras nosotros gestionamos sus obligaciones tributarias con precisión y profesionalismo.
      </p>
      <p>
        Con años de experiencia y un equipo altamente capacitado, nos aseguramos de ofrecer soluciones
        personalizadas adaptadas a las necesidades específicas de cada cliente.
      </p>
    </div>
    <div className="about-image">
      <img src={logo} alt="Equipo de Black Lion" />
    </div>
  </div>
</section>


      {/* Sección Contacto */}
      <section id="contact" className="contact section">
        <h2>Contacto</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label>Nombre</label>
            <input type="text" name="from_name" placeholder="Ingresa tu nombre" required />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="from_email" placeholder="Ingresa tu email" required />
          </div>
          <div className="form-group">
            <label>Mensaje</label>
            <textarea name="message" placeholder="Escribe tu mensaje" required></textarea>
          </div>
          <button type="submit">Enviar</button>
        </form>

        {/* Mensaje de confirmación */}
        {showMessage && <div className="confirmation-message">¡Correo enviado con éxito!</div>}

        {/* Mapa */}
        <div className="map-container">
          <SimpleMap />
        </div>

        {/* Dirección */}
        <div className="address">
          <h3>Nuestra Dirección</h3>
          <p>Vía Trentino 5710, Saucito, 31110 Chihuahua, Chih.</p>
        </div>
      </section>

      {/* Pie de página */}
      <footer className="footer">
        <p>© 2024 Black Lion. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
